import React, {useEffect} from 'react';

const CorporateCharacter = () => {
    useEffect(() => {
        window.location.href = '/careers/new/about-us#Shibacorn'
    }, []);

    return null;
};

export default CorporateCharacter;
